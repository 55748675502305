import React from 'react';

function Nav() {
    return (
        <div id="nav">
            <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand" href="/">
                    <img src="/images/Phil-Roberts_black_low-res.png" width="180" height="79" alt="Phil Roberts Photography"/>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent1">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active"><a className="nav-link" href="/">Home</a></li>
                        <li className="nav-item"><a className="nav-link disabled" href="#">About</a></li>
                        <li className="nav-item"><a className="nav-link disabled" href="#">Thoughts</a></li>
                        <li className="nav-item"><a className="nav-link disabled" href="#">Portfolio</a></li>
                        <li className="nav-item"><a className="nav-link" href="/galleries">Galleries</a></li>
                    </ul>
                    <form className="form-inline my-2 my-lg-0">
                        <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"/>
                        <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                    </form>
                </div>
            </nav>
        </div>
    );
}

export default Nav;